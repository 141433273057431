import React from "react"

const Buy = ({ vintage }) => {
  const url = vintage.purchaseUrl
  return (
    <>
    {url !== null  &&
      <a href={url} className="inline-block btn rounded font-bold text-white folly-sans no-break px-4 py-2 shadow transition duration-300 ease-in-out bg-green-500 hover:bg-green-400 " target="_blank">Buy Direct</a>
    }
    </>
  )

}

export default Buy

