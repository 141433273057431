import React from "react"
import gwdbLogo from "./../images/gwdb-logo.png"

const Footer = ({ }) => (
  <footer className="border-t mt-0 " >
    <div className="container mx-auto py-4 flex flex-col">
      <div className="md:w-1/4 mx-auto">
        <a href="https://www.gwdb.io" class="flex">
          <span className="w-24 pt-2"><img src={ gwdbLogo } className="block mx-auto" width="30px" height="30px" /></span>
          <p className="">Wineries, make your own wine information card at <b>gwdb.io</b></p>
        </a>
      </div>
      <div className="text-xs mt-6 text-center ">
        <span>Powered by: </span>
        <b><a href="https://www.gwdb.io">Global Wine Database</a></b>
      </div>
      <div className="text-xs mt-2 text-center">
        Copyright
        © {new Date().getFullYear()}
        {` `}
        <a href="https://www.folly.ai">Folly Enterprises</a>
      </div>

    </div>
  </footer>
)

export default Footer

