import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import BuyButton from "../components/buy"
import NextButton from "../components/next"
import winefollyLogo from "../images/winefolly-logo.svg"

const Header = ({vintage, vintage_ids }) => {

  return (
    <header className="header border-b m-0 p-0 py-2 mb-2" >
      <div className="container flex mx-auto justify-between ">
        <div className="w-3/4 flex text-left">
          <a href="https://winefolly.com" className="text-2xl uppercase pl-4 md:pl-20 ">
            <img src={winefollyLogo} height="auto" width="200px" id="winefolly-logo" className="my-auto" />
          </a>
          <span className="block h-full uppercase folly-sans text-gray-500 text-sm border-l pt-3 my-auto pl-2">Spotlight</span>
        </div>

        <div className="block w-1/4 text-center md:text-right pt-3 pb-1">
          <NextButton vintage_ids={vintage_ids} vintage={vintage} />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
