import React from "react"
import ApolloClient from 'apollo-boost'
import fetch from 'isomorphic-fetch';
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import HttpsRedirect from 'react-https-redirect'

import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"


import SEO from "../components/seo"
import Loader from "../components/loader"
import Chart from "../components/chart"
import Bottle from "../components/bottle"
import BuyButton from "../components/buy"
import NextButton from "../components/next"
import addLineBreaks from "../lib/addLineBreaks"

const api_url = 'https://api.gwdb.io/graphql'
const token = "VktTSjVYMTY0TUVVMFlCVDdXR0k6MXVYcVBuUS9DOVpQb1ZkV1RwZ1A0MFRHWnFGUTdzWkdtcEdaTnBEanFWSmlWL2Z2K1ZOZmcwUGw="

//const api_url = 'http://localhost:4040/graphql'
//const token = "MFc2OVJJSlZZRDM4TlhGUFVFTEg6V0ZkZFc1OGthVFE5c3NDVy84emlYSEQ1c1Bob1BhR1l5MkVYYkJWVlV5M0xPbjQ1cGwvbUFpa3I="

export const client = new ApolloClient({
  uri: api_url,
  headers: {
    "Authorization": `Basic ${token}`
  },
  fetch,
});

const WINE_QUERY = gql`
  query Wine($gwdb_id: ID!){
    vintage(id: $gwdb_id ) {
      id
      year
      bottleShot
      abv
      sugar
      ph
      acidity
      price
      currency
      varieties{
        name
        percentage
      }
      growingConditions
      harvest
      bottling
      winemaking
      aging
      nose
      palate
      appearance
      pairing
      startYear
      endYear
      purchaseUrl
      wine{
        id
        name
        colour{
          id
          name
        }
        style{
          id
          name
        }
      }
      winery{
        name
        region
        location{
          name
        }
      }
      appellation{
        name
      }
    }
  }
`

const WinePage = (params) => {

  const slug = params['*']

  const id = slug

  var vintage_ids = []
  if(params.location.search.indexOf('vintages=') > -1){
    vintage_ids = params.location.search.match(/vintages=([^&]*)/)[1].split(',')
  }

  const { loading, error, data } = useQuery(WINE_QUERY, {client: client, variables: { gwdb_id: id }})

  const format = (value) => {
    return addLineBreaks(value);
  }

  return (
    <HttpsRedirect>
  <Layout>
    {data &&
      <Header vintage={data.vintage} vintage_ids={vintage_ids} />
    }

    <main className="flex-grow md:mt-10">
      <div className="container mx-auto pb-16" >

    {data &&
      <SEO title={`${data.vintage.wine.name} - ${data.vintage.year} | ${data.vintage.winery.name}`} />
    }
    {loading &&
      <Loader />
    }
    {error && error.graphQLErrors.map(({message}, i) => (
          <span key={i}>{message}</span>
        ))
    }
    {data && data.vintage &&

      <div className="md:flex pb-10">
        <div className="md:w-1/3 mb-4 md:min-h-600">
          <Bottle vintage={ data.vintage } />
          <div className="text-center"> </div>
        </div>

        <div className="px-4 md:px-0 md:pr-4 md:w-2/3 pt-0 justify-center  ">
          <div className="w-full text-center md:text-left relative mb-4">
            <h2 className="md:w-3/4 mt-0 mb-0 text-md md:text-xl uppercase font-light folly-sans text-center md:text-left">{ data.vintage.winery.name }</h2>
            <h1 className="md:w-3/4 pb-0 mt-0 mb-0 text-4xl font-semibold leading-8 md:leading-none text-center md:text-left md:text-5xl ">{ data.vintage.wine.name } </h1>
            { data.vintage.purchaseUrl !== null &&
              <div className="hidden md:block md:absolute md:top-0 md right-0 md:-mt-2">
                <BuyButton vintage={data.vintage} />
              </div>
            }
          </div>

          <div className="flex flex-col uppercase font-light leading-snug text-sm pb-6 md:justify-between">
            {data.vintage.appellation &&
            <div className="flex-1 ">
                <h3 className="appellation mt-0 text-center md:text-left">{ data.vintage.appellation.name }</h3>
            </div>
            }
            <div className="flex-1">
              { data.vintage.winery.location.length > 0 &&
                <h3 className="location mt-0 mb-2 text-center md:text-left">{ data.vintage.winery.location.map((l) => l.name).splice(1).join(', ') }</h3>
              }
            </div>
            <div className="flex-1 ">
              <h3 className="mt-0 text-center md:text-left"><span className="font-light folly-numbers">{ data.vintage.year === -1 ? 'Non' : data.vintage.year }</span></h3>
            </div>
            { data.vintage.purchaseUrl !== null &&
              <div className="mx-auto md:hidden">
                <BuyButton vintage={data.vintage} />
              </div>
            }
          </div>

          <div className="flex flex-col flex-col-reverse md:flex-row uppercase md:border-t md:border-b">
            <div className="md:w-3/4 lg:w-2/3 md:border-r flex flex-col space-between">
              <div className="chart-inner-container hide-donut h-150 lg:h-200 pr-4 lg:pr-8 -ml-48 mt-8 -mr-4 md:mr-0 pb-6" >
                <Chart colour={data.vintage.wine.colour.name} varieties={data.vintage.varieties} />
              </div>
              <div className="flex uppercase folly-sans border-t md:border-t-0 border-b md:border-b-0">

                <div className="flex flex-col flex-1 justify-center items-center text-center py-4">
                  <p className="text-xl font-semibold folly-numbers">{data.vintage.abv === null ? '-' : `${data.vintage.abv}%`}</p>
                  <h3 className="m-0 font-light text-sm text-center">ABV</h3>
                </div>

                <div className="flex flex-col flex-1 justify-center items-center text-center">
                  <p className=" text-xl font-semibold folly-numbers">{data.vintage.ph === null ? '-' : data.vintage.ph}</p>
                  <h3 className="m-0 mr-1 font-light text-sm">pH</h3>
                </div>

                <div className="flex flex-col flex-1 justify-center items-center text-center">
                  <p className="text-xl font-semibold folly-numbers">{data.vintage.acidity === null ? '-' : data.vintage.acidity}</p>
                  <h3 className="m-0 mr-1 font-light text-sm">TA</h3>
                </div>

                <div className="flex flex-col flex-1 justify-center items-center text-center">
                  <p className="text-xl font-semibold folly-numbers">{data.vintage.sugar === null ? '-' : data.vintage.sugar}</p>
                  <h3 className="m-0 mr-1 font-light text-sm">RS</h3>
                </div>

              </div>
            </div>
            <div className="md:w-1/4 lg:w-1/3 flex md:flex-col md:justify-around md:items-center py-4 md:py-0 border-b md:border-b-0 border-t md:border-t-0">
              <div className="flex flex-col flex-1 md:flex-auto justify-center md:justify-end items-bottom text-center md:pb-2 border-r md:border-0">
                <p className="font-semibold text-lg lg:text-xl folly-numbers ">${data.vintage.price} {data.vintage.currency}</p>
                <h3 className="m-0 mr-1 font-light text-sm ">Cellar Price</h3>
              </div>
              <div className="flex flex-col flex-1 md:flex-auto justify-center md:justify-start md:items-top text-center md:pt-2 ">
                <p className="font-semibold text-lg lg:text-xl folly-numbers">
                  {data.vintage.startYear !== null && `${data.vintage.startYear}`}
                  {data.vintage.startYear !== null && `-`}
                  {data.vintage.endYear === null && `-`}
                  {data.vintage.endYear}
                </p>
                <h3 className="m-0 mr-1 font-light text-sm">Drink Window</h3>
              </div>
            </div>
          </div>


          <div className="main-content pt-6 px-8 lg:px-0 lg:pr-4">

            {data.vintage.growingConditions &&
              <div className="mb-10">
                <h3>Growing Conditions</h3>
                <p>{format(data.vintage.growingConditions)}</p>
              </div>
            }

            {data.vintage.harvest  &&
              <div className="mb-10">
                <h3>Harvest</h3>
                <p>{format(data.vintage.harvest)}</p>
              </div>
            }

            {data.vintage.bottling &&
              <div className="mb-10">
                <h3>Bottling</h3>
                <p>{format(data.vintage.bottling)}</p>
              </div>
            }

            {data.vintage.winemaking  &&
              <div className="mb-10">
                <h3>Winemaking</h3>
                <p>{format(data.vintage.winemaking)}</p>
              </div>
            }

            {data.vintage.aging &&
              <div className="mb-10">
                <h3>Aging</h3>
                <p>{format(data.vintage.aging)}</p>
              </div>
            }

            {data.vintage.nose  &&
              <div className="mb-10">
                <h3>Nose</h3>
                <p>{format(data.vintage.nose)}</p>
              </div>
            }

            {data.vintage.palate &&
              <div className="mb-10">
                <h3>Palate</h3>
                <p>{format(data.vintage.palate)}</p>
              </div>
            }

            {data.vintage.appearance &&
              <div className="mb-10">
                <h3>Appearance</h3>
                <p>{format(data.vintage.appearance)}</p>
              </div>
            }

            {data.vintage.pairing &&
              <div className="mb-10">
                <h3>Food Pairing</h3>
                <p>{format(data.vintage.pairing)}</p>
              </div>
            }

          </div>

        </div>

      </div>
    }
    </div>
    </main>
    <Footer />

  </Layout>
  </HttpsRedirect>
  )
}

export default WinePage
