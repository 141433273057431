import React from "react"

const Next = ({ vintage_ids, vintage }) => {
  if(vintage_ids.length == 0){
    return null
  }else{
    var idx = vintage_ids.indexOf(vintage.id)
    var nextIdx = idx + 1
    var next = vintage_ids[nextIdx] ? vintage_ids[nextIdx] : vintage_ids[0]
    const url = `/wine/${next}?vintages=${vintage_ids.join(',')}`
    return (
      <>
        <a href={url} className="btn rounded bg-gray-100 font-bold px-4 py-2 folly-sans border hover:text-red-800 hover:bg-gray-200 hover:border-gray-500 transition ease-in-out  duration-200">Next Wine</a>
      </>
    )
  }

}

export default Next

