import React from "react"
import { ResponsivePie } from '@nivo/pie'

const Chart = ({ colour, varieties }) => {

  let varietyData = varieties.map((v) => {
    let name = v.name
    let percentage = 0
    let raw_value = v.percentage
    let parsed_value = parseInt(raw_value)
    if(Number.isInteger(parsed_value)){
      percentage = parsed_value
    }else if(varieties.length == 1){
      percentage = 100
    }else{
      let fraction = 1/varieties.length;
      percentage = Number.parseFloat(fraction * 100).toPrecision(2);
    }
    return(
      {id: `${percentage}% ${name}`, value: percentage}
    )
  })

  let themes = {
    "White": ["#a6d169", "#c7db6a", "#e7e56c"],
    "Red": ["#b52347", "#d6294f", "#f24e5a"],
    "Rosé": ["#f6998b", "#f9ad91", "#fcc198"],
    "Orange": ["#f79320", "#faa442", "#fcb766"],
    "Brown": ["#d18034", "#d8924c", "#e1a668"]
  }

  let theme = themes[colour];

  return(
    <ResponsivePie
        data={varietyData}
        sortByValue={true}
        innerRadius={0.75}
        padAngle={0.7}
        cornerRadius={0}
        colors={theme}
        borderWidth={1}
        borderColor={'white'}
        enableRadialLabels={false}
        enableSlicesLabels={false}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        defs={[ ]}
        fill={[ ]}
        legends={[
            {
                anchor: 'right',
                direction: 'column',
                itemWidth: 180,
                itemHeight: 22,
                itemTextColor: '#999',
                symbolSize: 16,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
    />
  )
}

export default Chart

